.home-page {
  display: grid;
  height: 100vh;
  width: 100vw;
}

.prefilled-alert {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  height: 80px;
  border-radius: 21px;
  color: white;
}

.payment-overlay {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 80% 20%;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  background-color: #151515;
  border-radius: 31px;
  height: 80%;
  width: 70%;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, .1);
}


.indicators {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #fc982e;
}


.image {
  background-size: cover;
  background-position: bottom;
  background: url("../../assets/images/payment_bg.png");
}

.fields {
  flex: 10;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-right: 3%;
  box-sizing: border-box;
  padding-left: 3%;
  background-color: #fff;
}

.fields>div {
  width: 80%;
}



.card-and-type {
  display: flex;
  flex-direction: row;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .payment-overlay {
    height: 80%;
    width: 70%;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1000px) and (max-width: 1280px) {
  .payment-overlay {
    height: 80%;
    width: 90%;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .payment-overlay {
    height: 80%;
    width: 90%;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */
  .payment-overlay {
    height: 80%;
    width: 90%;
  }

}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */
  .payment-overlay {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    flex-direction: column-reverse;
  }

  .card-and-type {
    display: flex;
    flex-direction: column;
  }


}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */
  .payment-overlay {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    flex-direction: column-reverse;
  }

  .card-and-type {
    display: flex;
    flex-direction: column;
  }

  .indicators {
    height: 70px;
    position: fixed;
    bottom: 0;
  }

  .fields>div {
    padding-bottom: 60%;
    padding-top: 30%;
    width: 100%;
  }

}