


.card-element {
    /* height: 15rem; */
    width: 100%;
    border-radius: 21px;
    background-color: rgba(252, 152, 47, .1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 3%;
    box-shadow: 0px 0px 10px 0px rgba(252, 152, 47, .1);
    border: .3px solid rgba(252, 152, 47, .1);
    box-sizing: border-box;
}

.sorter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.card-logo {
    height: 40px;
    width: 60px;
    background-image: url("../../assets/images/card_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.input-handler {
    direction: ltr;
    /* margin: 0px 10px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.bank-icon {
    height: 50px;
    width: 50px;
    margin-right: 5px;
    border-radius: 11px;
    background-color: white;
    background-size: contain;
    background-position: center;
}


.exp-cvc {
    margin-top: 10px;
    width: 100%;
}
.cvc-handler {
    margin-top: 10px;
    width: 100%;
}

.exp-handler {
    direction: ltr;
    /* margin: 0px 10px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.card-item {
    width: 100%;
}

.adm-form {
    width: 100%;
}

.adm-list {
    background-color: white;
    border: none;
    border-radius: 14px;
}

.center-text {
    text-align: center;
    background-color: white;
    border: none;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 800;
    width: 100%;
}
.center-text:focus {
    border: none;
    outline: none;
 }

.card-invalid {
    border: 1px solid rgba(255, 0, 0, 0.3);
}

.inquiry-handler {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}