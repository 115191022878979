


.input-continue {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.phone-input {
    width: 100%;
}