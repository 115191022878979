

.phone-code-verification-element {
    width: 100%;
}

.verification-element {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.code-retry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.field-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.fullwidth-input {
    width: 100% ;
}