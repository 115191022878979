


.vendor-info {
    padding: 0px 20px;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: rgba(252,152,47,1);
    border-bottom-right-radius: 21px;
    border-bottom-left-radius: 21px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10rem;
}

.vendor-info-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

}

.vendor-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.vendor-avatar {
    height: 60px;
    width: 60px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: white !important;
    background-size: contain !important;
    border-radius: 50%;
}


.payment-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .vendor-info {
        min-height: 90px;
        height: calc(100% - 90%);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        border-radius: 0;
        transition: all .3s ease-in-out;
    }
    
  }