@font-face {
  font-family: Shabnam;
  src: url("./assets/fonts/Shabnam.ttf");
}


html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;

  font-family: "Shabnam";

  height: 100%;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-color: #FBFBFB;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}


input:invalid {
  border: 1px solid rgba(252, 152, 47, 1);
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.toast-wrapper>* {
  font-family: "Shabnam", 'Courier New', Courier, monospace !important;
  font-size: 10pt;
}


/* Sized Boxes */
.sized-box-width-10 {
  width: 10px;
}

.sized-box-width-20 {
  width: 20px;
}

.sized-box-width-40 {
  width: 40px;
}

.sized-box-width-50 {
  width: 50px;
}




.sized-box-height-10 {
  height: 10px;
}

.sized-box-height-20 {
  height: 20px;
}

.sized-box-height-40 {
  height: 40px;
}

.sized-box-height-50 {
  height: 50px;
}

::-webkit-scrollbar {
  display: none;
}

/* Buttons */
.button-element {
  color: #fff;
  font-family: Shabnam;
  font-weight: bold;
  background-color: rgba(252, 152, 47, 1);
  border: none;
  outline: none;
  height: 60px;
  width: 40%;
  cursor: pointer;
  border-radius: 14px;
  transition: .6s ease-in-out;
}

.button-element:hover {
  opacity: 0.3;
}

.button-element:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}


/* Font Sizes */
.title-style {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 14pt;
}

.title-low-sized {
  margin: 0;
  padding: 0;
  font-weight: lighter;
  font-size: 9pt;
}

h1, h2, h3, h4, h5, h6 {
  /* color: white; */
}


/* TextField Styles */

.input-style {
  border: none;
  height: 60px;
  border-radius: 14px;
  text-align: center;
  font-weight: bold;
  font-family: Shabnam;
  background-color: white;
}

.input-style:focus {
  border: none;
  outline: none;
}





/* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  /* CSS */

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */

}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .title-style {
    font-size: 11pt;
  }

  .input-style {
    height: 50px;
  }

  .button-element {
    height: 50px;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */
  .title-style {
    font-size: 11pt;
  }

  .input-style {
    height: 50px;
  }

  .button-element {
    height: 50px;
  }
}