


.navigation-bar-element {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-rows: 25% 25% 25% 25%;
}

.nav-item-icon {
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: cover;
}



/* Icons */
.add {
    background-image: url("../../assets/images/add.svg");
}
.phone {
    background-image: url("../../assets/images/phone.svg");
}
.info {
    background-image: url("../../assets/images/info.svg");
}
.help {
    background-image: url("../../assets/images/help.svg");
}


.white-text {
    color: white;
}

.nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 10em;
    background-color: rgba(255, 255, 255, .4);
    transition: .3s ease-in-out;
}

.nav-item-selected {
    background-color: rgba(255, 255, 255, 0);
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .navigation-bar-element {
        flex-direction: row;
    }
    .nav-item-icon {
        height: 20px;
        width: 20px;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .navigation-bar-element {
        flex-direction: row;
        height: 80px;
    }
    .nav-item-icon {
        height: 20px;
        width: 20px;
    }
    
  }